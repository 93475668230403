<div class="align-middle" style="height: 450px; margin-top: 250px;">




    <div class="text-center">
      <img src="../assets/logo_digan.png" alt="" srcset="" id="logo_digan" class="animate__animated animate__bounce">
  </div>
  <div class="text-center">
   <h1 class="animate__animated animate__bounce">Coming Soon</h1>
  </div>




</div>

<router-outlet></router-outlet>
